/* eslint-disable jsx-a11y/anchor-is-valid */
//React
import {FC, useEffect, useState} from 'react'

//React-Router-Dom
import {Link, useLocation} from 'react-router-dom'

//helpers
import {KTIcon} from '../../../helpers'

//theme
import {ThemeModeSwitcher} from '../../../partials'

//Redux - Dispatch - Actions - State
import {useDispatch, useSelector} from 'react-redux'
import {selectToken, selectCurrentUser, setAuthState} from '../../../../app/redux/slice/authSlice'
import {useLazyMessagesQuery, useMessagesQuery} from '../../../../app/api/endpoints/summary'
import {useNotificationsQuery} from '../../../../app/api/endpoints/notification'
import {useLazyGetActivationQuery} from '../../../../app/api/endpoints/activation'
import {useAuthorisationStaffQuery} from '../../../../app/api/endpoints/staff'

//i18n - react-intl - internationalization - locale -localization
import {useIntl} from 'react-intl'

import ActivationCodeModal from '../../../../app/components/modalForms/activationCodeModal'

const Topbar: FC = () => {
  const intl = useIntl()
  const currentUser = useSelector(selectCurrentUser)
  const is_superuser = currentUser?.is_superuser
  const is_staff = currentUser?.is_staff
  const is_customer = currentUser?.is_customer
  const in_app_messaging = currentUser?.in_app_messaging
  const [requestMessages] = useLazyMessagesQuery()
  const [requestGetActivation, {isLoading: isLoadingGetActivation}] = useLazyGetActivationQuery()

  const [activationCodeModalState, setActivationCodeModalState] = useState({
    modalVisible: false,
    activation_code: '',
  })

  const {data: authorisationData} = useAuthorisationStaffQuery({
    endpointData: {
      id: currentUser?.id,
    },
    toastSuccessMessageStatus: false,
    toastErrorMessageStatus: false,
  })

  const {
    data: dataMessages,
    isLoading: isLoadingMessages,
    isFetching: isFetchingMessages,
  } = useMessagesQuery(
    {
      endpointData: {},
      toastSuccessMessageStatus: false,
      toastErrorMessageStatus: true,
    },
    {
      pollingInterval: 5000,
      skip: in_app_messaging === true ? false : true,
    }
  )

  const {
    data: dataNotifications,
    isLoading: isLoadingNotifications,
    isFetching: isFetchingNotifications,
  } = useNotificationsQuery(
    {
      endpointData: {
        limit: -1,
      },
      toastSuccessMessageStatus: false,
      toastErrorMessageStatus: true,
    },
    {
      pollingInterval: 60000,
      skip: false,
    }
  )

  const staffExists = dataMessages?.data?.staff && Object.keys(dataMessages.data.staff).length > 0
  const customerExists =
    dataMessages?.data?.customer && Object.keys(dataMessages.data.customer).length > 0
  const notificationsExists =
    dataNotifications?.data?.data &&
    dataNotifications?.data?.data.filter((x) => x.is_read == false).length > 0

  const getActivation = async () => {
    try {
      const responseGetActivation = await requestGetActivation({
        endpointData: null,
        toastSuccessMessageStatus: false,
        toastErrorMessageStatus: true,
      }).unwrap()

      console.log(responseGetActivation?.data?.activation_code)

      setActivationCodeModalState({
        modalVisible: true,
        activation_code: responseGetActivation?.data?.activation_code,
      })
    } catch (error) {}
  }

  const isAuthorizedActivationManagement =
    is_superuser === true
      ? true
      : is_customer === true
      ? false
      : is_staff === true
      ? authorisationData?.data[0]?.activation_management != undefined
        ? authorisationData?.data[0]?.activation_management
        : null
      : null

  const isAuthorizedCustomerManagement =
    is_superuser === true
      ? true
      : is_customer === true
      ? false
      : is_staff === true
      ? authorisationData?.data[0]?.customer_management != undefined
        ? authorisationData?.data[0]?.customer_management
        : null
      : null

  return (
    <div className='d-flex flex-shrink-0'>
      {/* begin::Invite user */}
      <div className='d-flex ms-3'>
        {is_customer === true ? (
          <Link
            to={`/customer-management/customer/${currentUser?.id}/memberships`}
            className='btn btn-flex flex-center bg-body btn-color-gray-700 btn-active-color-primary w-40px w-md-auto h-40px px-0 px-md-6'
          >
            <KTIcon iconName='plus' className='fs-2 text-primary me-0 me-md-2' />
            <span className='d-none d-md-inline'>
              {intl.formatMessage({id: 'FORM.BUTTON.BUY_MEMBERSHIP'})}
            </span>
          </Link>
        ) : (
          <>
            {isAuthorizedCustomerManagement === true ? (
              <a
                href='#'
                className='btn btn-flex flex-center bg-body btn-color-gray-700 btn-active-color-primary w-40px w-md-auto h-40px px-0 px-md-6'
                data-bs-toggle='modal'
                data-bs-target='#modal_create_customer'
              >
                <KTIcon iconName='plus' className='fs-2 text-primary me-0 me-md-2' />
                <span className='d-none d-md-inline'>
                  {intl.formatMessage({id: 'FORM.BUTTON.CREATE_CUSTOMER'})}
                </span>
              </a>
            ) : null}

            {isAuthorizedActivationManagement === true ? (
              <button
                onClick={getActivation}
                className='btn btn-flex flex-center bg-body btn-color-gray-700 btn-active-color-primary w-40px w-md-auto h-40px px-0 px-md-6 ms-3'
                disabled={isLoadingGetActivation}
              >
                <KTIcon iconName='phone' className='fs-2 text-primary me-0 me-md-2' />

                <span className='d-none d-md-inline'>
                  {intl.formatMessage({id: 'FORM.BUTTON.ACTIVATIONCODE'})}
                </span>
                {isLoadingGetActivation ? <span className='pulse-ring' /> : null}
              </button>
            ) : null}
          </>
        )}
      </div>

      {/* begin::Theme mode */}
      <div className='d-flex align-items-center  ms-3'>
        <ThemeModeSwitcher toggleBtnClass=' flex-center bg-body btn-color-gray-600 btn-active-color-primary h-40px' />
      </div>
      {/* end::Theme mode */}

      {/* CHAT */}
      <div className='d-flex align-items-center ms-3'>
        {/* begin::Menu wrapper */}
        <div
          className={
            notificationsExists
              ? 'btn btn-icon btn-primary w-40px h-40px pulse pulse-white'
              : 'btn btn-icon bg-body btn-color-gray-600 btn-active-color-primary w-40px h-40px'
          }
          id='kt_drawer_notification_toggle'
        >
          <KTIcon iconName='notification' className='fs-2' />
          {notificationsExists ? <span className='pulse-ring' /> : null}
        </div>
        {/* end::Menu wrapper */}
      </div>
      {in_app_messaging ? (
        <div className='d-flex align-items-center ms-3'>
          {/* begin::Menu wrapper */}
          <div
            className={
              staffExists || customerExists
                ? 'btn btn-icon btn-primary w-40px h-40px pulse pulse-white'
                : 'btn btn-icon bg-body btn-color-gray-600 btn-active-color-primary w-40px h-40px'
            }
            id='kt_drawer_chat_toggle'
          >
            <KTIcon iconName='message-text-2' className='fs-2' />
            {staffExists || customerExists ? <span className='pulse-ring' /> : null}
          </div>
          {/* end::Menu wrapper */}
        </div>
      ) : null}

      {activationCodeModalState?.modalVisible ? (
        <ActivationCodeModal
          modalVisible={activationCodeModalState?.modalVisible}
          activation_code={activationCodeModalState?.activation_code}
          onCloseModal={() => {
            setActivationCodeModalState({
              modalVisible: false,
              activation_code: '',
            })
          }}
        />
      ) : null}
    </div>
  )
}

export {Topbar}
