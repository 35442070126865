//React
import React, {FC, useState, useEffect, useRef, useMemo} from 'react'

//i18n - react-intl - internationalization - locale -localization
import {useIntl} from 'react-intl'

//CLSX - Dynamic CSS Class Creator
import clsx from 'clsx'

//lodash
import _ from 'lodash'

//Typeahead
import {Typeahead} from 'react-bootstrap-typeahead'

import InputRangeSlider from '../../../../../components/inputRangeSlider'

//React Hook Form - Yup Validation
import {useForm, Controller} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as Yup from 'yup'

//Model
import {Package, Option} from '../../../../../models'

//Redux ToolKit API
import {
  useCreatePackageMutation,
  useUpdatePackageMutation,
} from '../../../../../api/endpoints/package'
import {useFACILITYsQuery} from '../../../../../api/endpoints/facility'
import {useCompanyQuery} from '../../../../../api/endpoints/company'
import {useLecturesQuery} from '../../../../../api/endpoints/lecture'

//Redux - Dispatch - Actions - State
import {useDispatch, useSelector} from 'react-redux'
import {selectToken, selectCurrentUser, setAuthState} from '../../../../../redux/slice/authSlice'
import {selectInitialData} from '../../../../../redux/slice/initialDataSlice'

//Helper
import {KTIcon} from '../../../../../../_metronic/helpers'

//Menu Component
import {ScrollComponent} from '../../../../../../_metronic/assets/ts/components'

interface FormModalProps {
  type: 'create' | 'update'
  value: Package | null
  onCloseModal: () => void
  modalVisible: boolean
}

const FormModal: React.FC<FormModalProps> = ({type, value, onCloseModal, modalVisible}) => {
  const intl = useIntl()
  const [fade, setFade] = useState(false)
  const scrollDivElement = document.querySelector('#modal_create_and_update_package_scroll')

  useEffect(() => {
    if (modalVisible) {
      setTimeout(() => setFade(true), 100)
    } else {
      setFade(false)
    }
  }, [modalVisible])

  useEffect(() => {
    if (fade) {
      ScrollComponent.reinitialization()
      if (scrollDivElement) {
        scrollDivElement.scrollTo(0, 0)
      }
    }
  }, [fade])

  console.log(value)

  const dispatch = useDispatch()
  const initialdata = useSelector(selectInitialData)
  console.log(initialdata)
  const [requestUpdate, {isLoading: updateIsLoading, isError: updateIsError, data: updateData}] =
    useUpdatePackageMutation()
  const [requestAdd, {isLoading: addIsLoading, isError: addIsError, data: addData}] =
    useCreatePackageMutation()
  const request = type === 'update' ? requestUpdate : requestAdd
  const isLoading = type === 'update' ? updateIsLoading : addIsLoading
  const isError = type === 'update' ? updateIsError : addIsError
  const data = type === 'update' ? updateData : addData

  const {data: lectureData} = useLecturesQuery({
    endpointData: {
      limit: -1,
    },
    toastSuccessMessageStatus: false,
    toastErrorMessageStatus: true,
  })

  const {data: facilityData} = useFACILITYsQuery({
    endpointData: {
      limit: -1,
    },
    toastSuccessMessageStatus: false,
    toastErrorMessageStatus: true,
  })

  const {data: companyInformationData} = useCompanyQuery({
    endpointData: {},
    toastSuccessMessageStatus: false,
    toastErrorMessageStatus: true,
  })

  const formSchema = Yup.object().shape({
    color: Yup.string().required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
    name: Yup.string().required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
    age_range: Yup.object({
      min_age: Yup.number().required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
      max_age: Yup.number().required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
    }),
    type: Yup.string().required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
    package_kind: Yup.string().required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
    length: Yup.string().required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
    credit_score: Yup.string().required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
    facility: Yup.string().required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
    fields: Yup.array()
      .required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'}))
      .min(1, intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
    gender: Yup.array()
      .required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'}))
      .min(1, intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
    price: Yup.string().required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),

    tax_rates: Yup.string().required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
    currency: Yup.string().required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
    appointment_cancellation_limit: Yup.number().required(
      intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})
    ),
    max_stopping_days: Yup.number()
      .max(
        companyInformationData?.data[0]?.max_stopping_days != undefined
          ? parseInt(companyInformationData?.data[0]?.max_stopping_days)
          : 0,
        intl.formatMessage(
          {id: 'FORM.VALIDATION.MAX_STOPPING_DAYS'},
          {name: companyInformationData?.data[0]?.max_stopping_days}
        )
      )
      .required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
    status: Yup.boolean().required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),

    opportunity_situation: Yup.boolean().required(
      intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})
    ),
    marketplace_status: Yup.boolean().required(
      intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})
    ),
    auto_credit_decrease: Yup.boolean().required(
      intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})
    ),
    auto_appointment: Yup.boolean().required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
    tax_inclusive: Yup.boolean().required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
    is_relational: Yup.boolean().required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
    relational_lectures: Yup.array().when('is_relational', {
      is: (val: boolean) => val === true,
      then: (schema) =>
        schema
          .required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'}))
          .min(1, intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
      otherwise: (schema) => schema.notRequired(),
    }),
  })

  const {
    register,
    handleSubmit,
    watch,
    setError,
    setValue,
    reset,
    control,
    formState: {errors, touchedFields, isSubmitting, isValid},
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      color: '',
      name: '',
      age_range: {
        min_age: 0,
        max_age: 100,
      },
      type: '',
      package_kind: '0',
      length: '',
      credit_score: '',
      facility: '',
      fields: [] as Option[],
      gender: [] as Option[],
      price: '',
      tax_rates: '',
      currency: '',
      max_stopping_days:
        companyInformationData?.data[0]?.max_stopping_days == undefined
          ? 0
          : companyInformationData?.data[0]?.max_stopping_days,
      appointment_cancellation_limit:
        companyInformationData?.data[0]?.appointment_cancellation_limit == undefined
          ? 0
          : companyInformationData?.data[0]?.appointment_cancellation_limit,
      status: true,
      opportunity_situation: true,
      marketplace_status: true,
      auto_credit_decrease: false,
      auto_appointment: true,
      tax_inclusive:
        companyInformationData?.data[0]?.tax_inclusive == undefined
          ? false
          : companyInformationData?.data[0]?.tax_inclusive,

      is_relational: false,

      relational_lectures: [] as Option[],
    },

    resolver: yupResolver(formSchema),
  })

  useEffect(() => {
    if (
      initialdata != null &&
      modalVisible &&
      type == 'update' &&
      value != null &&
      facilityData?.data?.data != undefined &&
      lectureData?.data?.data != undefined
    ) {
      setValue('color', value.color)
      setValue('name', value.name)
      setValue(
        'type',
        String(initialdata?.package_types.filter((val) => val.name == value.type)[0]?.id)
      )
      setValue(
        'package_kind',
        initialdata?.package_kind.filter((val) => val.id == value.package_kind)[0]?.id
      )
      setValue('length', String(value.length))
      setValue('credit_score', String(value.credit_score))
      setValue('facility', String(value.facility))
      setValue(
        'fields',
        value.fields
          .map((x: number) => initialdata?.categories.filter((val) => val.id == x)[0])
          .filter((item) => item !== undefined)
      )
      setValue(
        'gender',
        value.gender
          .map((x: string) => initialdata?.gender.filter((val) => val.id == x)[0])
          .filter((item) => item !== undefined)
      )
      setValue('price', String(value.price))
      setValue('tax_rates', String(value.tax_rates))
      setValue('max_stopping_days', value.max_stopping_days)
      setValue('appointment_cancellation_limit', value.appointment_cancellation_limit)
      setValue('currency', initialdata?.currency.filter((val) => val.name == value.currency)[0]?.id)
      setValue('status', value.status)
      setValue('opportunity_situation', value.opportunity_situation)
      setValue('marketplace_status', value.marketplace_status)
      setValue('age_range', value.age_range)
      setValue('auto_appointment', value.auto_appointment)
      setValue('auto_credit_decrease', value.auto_credit_decrease)
      setValue('tax_inclusive', value.tax_inclusive)
      setValue('is_relational', value.is_relational)
      setValue(
        'relational_lectures',
        value.relational_lectures
          .map((x: number) => lectureData?.data?.data.find((val) => val.id == x))
          .filter((item) => item !== undefined) // undefined olanları çıkartıyoruz
      )
    }
  }, [initialdata, modalVisible, type, value, facilityData, lectureData])

  const watchFormField = watch()

  const onSubmit = (formData: any) => {
    const sendFormdata = new FormData()

    Object.keys(formData).forEach(function (key) {
      if (key === 'age_range') {
        sendFormdata.append(key, JSON.stringify(formData.age_range))
      } else if (key === 'relational_lectures') {
        const dt = _.map(formData[key], (item) => item.id)

        sendFormdata.append(key, JSON.stringify(dt))
      } else if (typeof formData[key] === 'object') {
        formData[key].map((x: Option) => {
          sendFormdata.append(key, String(x.id))
        })
      } else {
        sendFormdata.append(key, formData[key])
      }
    })
    request({
      endpointData: type == 'create' ? sendFormdata : {id: value?.id, formData: sendFormdata},
      toastSuccessMessageStatus: true,
      toastErrorMessageStatus: true,
    })
  }

  useEffect(() => {
    if (!isLoading && !isError && data !== undefined) {
      reset()

      if (scrollDivElement) {
        scrollDivElement.scrollTo(0, 0)
      }
    }
  }, [isLoading, isError, data])

  console.log('value')
  console.log(value)

  return (
    <>
      <div
        className={clsx('modal fade d-block', {show: fade})}
        id='modal_create_and_update_package'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-1000px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <div className='modal-header'>
              {/* begin::Modal title */}
              <h2 className='fw-bolder'>
                {type === 'create'
                  ? intl.formatMessage({id: 'SCHEDULE_LECTURES.NEW_MEMBERSHİP_TITLE'})
                  : intl.formatMessage({id: 'SCHEDULE_LECTURES.UPDATE_MEMBERSHİP_TITLE'})}
              </h2>
              {/* end::Modal title */}

              {/* begin::Close */}
              <button
                type='button'
                className='btn btn-icon btn-sm btn-active-icon-primary'
                onClick={onCloseModal}
                style={{cursor: 'pointer'}}
              >
                <KTIcon iconName='cross' className='fs-1' />
              </button>
              {/* end::Close */}
            </div>
            {/* begin::Modal body */}
            <div className='modal-body scroll-y mx-5 my-7'>
              <form
                id='modal_create_and_update_package_form'
                className='form'
                onSubmit={handleSubmit(onSubmit)}
                noValidate
              >
                {/* begin::Scroll */}
                <div
                  className='d-flex flex-column scroll-y me-n7 pe-7 px-2'
                  id='modal_create_and_update_package_scroll'
                  data-kt-scroll='true'
                  data-kt-scroll-activate='{default: false, lg: true}'
                  data-kt-scroll-max-height='auto'
                  data-kt-scroll-dependencies='#modal_create_and_update_package_header'
                  data-kt-scroll-wrappers='#modal_create_and_update_package_scroll'
                  data-kt-scroll-offset='300px'
                >
                  <div className='fv-row mb-7'>
                    {/* begin::Label */}
                    <label className='required fw-bold fs-6 mb-2 w-100' style={{textAlign: 'left'}}>
                      {intl.formatMessage({id: 'FORM.LABEL.MEMBERSHIPS_NAME'})}
                    </label>
                    {/* end::Label */}

                    {/* begin::Input */}
                    <input
                      type='text'
                      {...register('name')}
                      placeholder={''}
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {'is-invalid': touchedFields?.name && errors?.name},
                        {
                          'is-valid': touchedFields?.name && !errors?.name,
                        }
                      )}
                    />
                    {touchedFields?.name && errors?.name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{errors?.name?.message}</span>
                        </div>
                      </div>
                    )}

                    {/* end::Input */}
                  </div>

                  <div className='fv-row mb-7'>
                    {/* begin::Label */}
                    <label className='required fw-bold fs-6 mb-2 w-100' style={{textAlign: 'left'}}>
                      {`${intl.formatMessage({id: 'FORM.LABEL.AGE_RANGE'})} (${
                        watchFormField.age_range.min_age
                      } - ${watchFormField.age_range.max_age})`}
                    </label>
                    {/* end::Label */}

                    <Controller
                      control={control}
                      name='age_range'
                      render={({field: {onChange, onBlur, value, ref, name}}) => (
                        <InputRangeSlider
                          min={0}
                          max={100}
                          initialMin={value.min_age}
                          initialMax={value.max_age}
                          onChange={(min_age: number, max_age: number) => {
                            //setValue('age_range',{min_age,max_age})
                            onChange({min_age, max_age})
                          }}
                        />
                      )}
                    />

                    {touchedFields?.age_range && errors?.age_range && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{errors?.age_range?.message}</span>
                        </div>
                      </div>
                    )}

                    {/* end::Input */}
                  </div>

                  <div className='row'>
                    <div className='col-4'>
                      <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label
                          className='required fw-bold fs-6 mb-2 w-100'
                          style={{textAlign: 'left'}}
                        >
                          {intl.formatMessage({id: 'FORM.LABEL.COLOR'})}
                        </label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                          style={{
                            height: 43.57,
                          }}
                          type='color'
                          {...register('color')}
                          placeholder={''}
                          className={clsx(
                            'form-select form-select-solid  mb-3 mb-lg-0',
                            {'is-invalid': touchedFields?.color && errors?.color},
                            {
                              'is-valid': touchedFields?.color && !errors?.color,
                            }
                          )}
                        />
                        {touchedFields?.color && errors?.color && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{errors?.color?.message}</span>
                            </div>
                          </div>
                        )}

                        {/* end::Input */}
                      </div>
                    </div>

                    <div className='col-8'>
                      <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label
                          className='required fw-bold fs-6 mb-2 w-100'
                          style={{textAlign: 'left'}}
                        >
                          {intl.formatMessage({id: 'FORM.LABEL.GENDER'})}
                        </label>
                        {/* end::Label */}

                        <Controller
                          control={control}
                          name='gender'
                          render={({field: {onChange, onBlur, value, ref, name}}) => (
                            <Typeahead
                              id='modal_create_and_update_package_gender'
                              clearButton
                              multiple
                              labelKey={'name'}
                              isValid={
                                touchedFields[name] != undefined &&
                                touchedFields[name] &&
                                (errors[name] == undefined || !errors[name])
                              }
                              isInvalid={
                                touchedFields[name] != undefined && errors[name] != undefined
                              }
                              instanceRef={ref}
                              options={initialdata?.gender == undefined ? [] : initialdata?.gender}
                              onChange={onChange}
                              onBlur={onBlur}
                              selected={value as Option[]}
                              emptyLabel={intl.formatMessage({id: 'FORM.WARNING.EMPTY_LABEL'})}
                            />
                          )}
                        />

                        {touchedFields?.gender && errors?.gender && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{errors?.gender?.message}</span>
                            </div>
                          </div>
                        )}

                        {/* end::Input */}
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-2'>
                      <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label
                          className='required fw-bold fs-6 mb-2 w-100'
                          style={{textAlign: 'left'}}
                        >
                          {intl.formatMessage({id: 'FORM.LABEL.PACKAGE_TYPE'})}
                        </label>
                        {/* end::Label */}

                        <Controller
                          control={control}
                          name='package_kind'
                          render={({field: {onChange, onBlur, value, ref, name}}) => (
                            <select
                              ref={ref}
                              name={name}
                              onBlur={onBlur}
                              onChange={(event) => {
                                const kind = event.target.value

                                if (kind != '0') {
                                  setValue('credit_score', '0')
                                }

                                onChange(event)
                              }}
                              value={value}
                              className={clsx(
                                'form-select form-select-solid  mb-3 mb-lg-0',
                                {'is-invalid': touchedFields?.package_kind && errors?.package_kind},
                                {
                                  'is-valid': touchedFields?.package_kind && !errors?.package_kind,
                                }
                              )}
                            >
                              <option value=''>
                                {intl.formatMessage({id: 'FORM.PLACEHOLDER.SELECT'})}
                              </option>
                              {initialdata &&
                                initialdata?.package_kind &&
                                initialdata?.package_kind
                                  .filter((x) => x?.id === 0)
                                  .map((opt: any, index: number) => (
                                    <option key={index} value={String(opt.id)}>
                                      {opt.name}
                                    </option>
                                  ))}
                            </select>
                          )}
                        />

                        {touchedFields?.package_kind && errors?.package_kind && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{errors?.package_kind?.message}</span>
                            </div>
                          </div>
                        )}

                        {/* end::Input */}
                      </div>
                    </div>
                    <div className='col-2'>
                      <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label
                          className='required fw-bold fs-6 mb-2 w-100'
                          style={{textAlign: 'left'}}
                        >
                          {intl.formatMessage({id: 'FORM.LABEL.PACKAGE_CREDIT_SCORE'})}
                        </label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                          type='text'
                          {...register('credit_score')}
                          placeholder={''}
                          className={clsx(
                            'form-control form-control-solid  mb-3 mb-lg-0',
                            {'is-invalid': touchedFields?.credit_score && errors?.credit_score},
                            {
                              'is-valid': touchedFields?.credit_score && !errors?.credit_score,
                            }
                          )}
                          disabled={watchFormField.package_kind == '0' ? false : true}
                        />
                        {touchedFields?.credit_score && errors?.credit_score && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{errors?.credit_score?.message}</span>
                            </div>
                          </div>
                        )}

                        {/* end::Input */}
                      </div>
                    </div>
                    <div className='col-2'>
                      <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label
                          className='required fw-bold fs-6 mb-2 w-100'
                          style={{textAlign: 'left'}}
                        >
                          {intl.formatMessage({id: 'FORM.LABEL.PACKAGE_PERIOD'})}
                        </label>
                        {/* end::Label */}
                        <select
                          {...register('type')}
                          className={clsx(
                            'form-select form-select-solid  mb-3 mb-lg-0',
                            {'is-invalid': touchedFields?.type && errors?.type},
                            {
                              'is-valid': touchedFields?.type && !errors?.type,
                            }
                          )}
                        >
                          <option value=''>
                            {intl.formatMessage({id: 'FORM.PLACEHOLDER.SELECT'})}
                          </option>
                          {initialdata &&
                            initialdata?.package_types &&
                            initialdata?.package_types.map((opt: any, index: number) => (
                              <option key={index} value={String(opt.id)}>
                                {opt.name}
                              </option>
                            ))}
                        </select>

                        {touchedFields?.type && errors?.type && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{errors?.type?.message}</span>
                            </div>
                          </div>
                        )}

                        {/* end::Input */}
                      </div>
                    </div>
                    <div className='col-2'>
                      <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label
                          className='required fw-bold fs-6 mb-2 w-100'
                          style={{textAlign: 'left'}}
                        >
                          {intl.formatMessage({id: 'FORM.LABEL.PACKAGE_DURATION'})}
                        </label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                          type='text'
                          {...register('length')}
                          placeholder={''}
                          className={clsx(
                            'form-control form-control-solid  mb-3 mb-lg-0',
                            {'is-invalid': touchedFields?.length && errors?.length},
                            {
                              'is-valid': touchedFields?.length && !errors?.length,
                            }
                          )}
                        />
                        {touchedFields?.length && errors?.length && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{errors?.length?.message}</span>
                            </div>
                          </div>
                        )}

                        {/* end::Input */}
                      </div>
                    </div>
                    <div className='col-4'>
                      <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label
                          className='required fw-bold fs-6 mb-2 w-100'
                          style={{textAlign: 'left'}}
                        >
                          {intl.formatMessage({id: 'FORM.LABEL.FACILITY'})}
                        </label>
                        {/* end::Label */}

                        <Controller
                          control={control}
                          name='facility'
                          render={({field: {onChange, onBlur, value, ref, name}}) => (
                            <select
                              ref={ref}
                              name={name}
                              onBlur={onBlur}
                              onChange={(event) => {
                                const selectFacility =
                                  facilityData &&
                                  facilityData?.data?.data &&
                                  facilityData?.data?.data.filter(
                                    (x) => x.id == event.target.value
                                  )[0]

                                console.log(selectFacility)

                                if (selectFacility) {
                                  setValue('currency', selectFacility.currency)
                                  setValue('tax_rates', selectFacility.tax_rates)
                                  setValue(
                                    'auto_credit_decrease',
                                    selectFacility.auto_credit_decrease
                                  )
                                }

                                onChange(event)
                              }}
                              value={value}
                              className={clsx(
                                'form-select form-select-solid  mb-3 mb-lg-0',
                                {'is-invalid': touchedFields?.package_kind && errors?.package_kind},
                                {
                                  'is-valid': touchedFields?.package_kind && !errors?.package_kind,
                                }
                              )}
                            >
                              <option value=''>
                                {intl.formatMessage({id: 'FORM.PLACEHOLDER.SELECT'})}
                              </option>
                              {facilityData &&
                                facilityData?.data?.data &&
                                facilityData?.data?.data.map((opt: any, index: number) => (
                                  <option key={index} value={String(opt.id)}>
                                    {opt.name}
                                  </option>
                                ))}
                            </select>
                          )}
                        />

                        {touchedFields?.facility && errors?.facility && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{errors?.facility?.message}</span>
                            </div>
                          </div>
                        )}

                        {/* end::Input */}
                      </div>
                    </div>
                  </div>

                  <div className='fv-row mb-7'>
                    {/* begin::Label */}
                    <label className='required fw-bold fs-6 mb-2 w-100' style={{textAlign: 'left'}}>
                      {intl.formatMessage({id: 'FORM.LABEL.BRANCHES'})}
                    </label>
                    {/* end::Label */}

                    <Controller
                      control={control}
                      name='fields'
                      render={({field: {onChange, onBlur, value, ref, name}}) => (
                        <Typeahead
                          id='modal_create_and_update_package_fields'
                          clearButton
                          multiple
                          labelKey={'name'}
                          isValid={
                            touchedFields[name] != undefined &&
                            touchedFields[name] &&
                            (errors[name] == undefined || !errors[name])
                          }
                          isInvalid={touchedFields[name] != undefined && errors[name] != undefined}
                          instanceRef={ref}
                          options={
                            initialdata?.categories == undefined ? [] : initialdata?.categories
                          }
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value as Option[]}
                          emptyLabel={intl.formatMessage({id: 'FORM.WARNING.EMPTY_LABEL'})}
                        />
                      )}
                    />

                    {touchedFields?.fields && errors?.fields && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{errors?.fields?.message}</span>
                        </div>
                      </div>
                    )}

                    {/* end::Input */}
                  </div>

                  <div className='row'>
                    <div className='col-2'>
                      <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label
                          className='required fw-bold fs-6 mb-2 w-100'
                          style={{textAlign: 'left'}}
                        >
                          {intl.formatMessage({id: 'FORM.LABEL.PRICE'})}
                        </label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                          type='number'
                          {...register('price')}
                          placeholder={''}
                          className={clsx(
                            'form-control form-control-solid  mb-3 mb-lg-0',
                            {'is-invalid': touchedFields?.price && errors?.price},
                            {
                              'is-valid': touchedFields?.price && !errors?.price,
                            }
                          )}
                        />
                        {touchedFields?.price && errors?.price && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{errors?.price?.message}</span>
                            </div>
                          </div>
                        )}

                        {/* end::Input */}
                      </div>
                    </div>
                    <div className='col-2'>
                      {' '}
                      <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label
                          className='required fw-bold fs-6 mb-2 w-100'
                          style={{textAlign: 'left'}}
                        >
                          {intl.formatMessage({id: 'FORM.LABEL.TAX_RATES'})}
                        </label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                          type='number'
                          {...register('tax_rates')}
                          placeholder={''}
                          className={clsx(
                            'form-control form-control-solid  mb-3 mb-lg-0',
                            {'is-invalid': touchedFields?.tax_rates && errors?.tax_rates},
                            {
                              'is-valid': touchedFields?.tax_rates && !errors?.tax_rates,
                            }
                          )}
                        />
                        {touchedFields?.tax_rates && errors?.tax_rates && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{errors?.tax_rates?.message}</span>
                            </div>
                          </div>
                        )}

                        {/* end::Input */}
                      </div>
                    </div>
                    <div className='col-2'>
                      <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label
                          className='required fw-bold fs-6 mb-2 w-100'
                          style={{textAlign: 'left'}}
                        >
                          {intl.formatMessage({id: 'FORM.LABEL.CURRENCY'})}
                        </label>
                        {/* end::Label */}
                        <select
                          {...register('currency')}
                          className={clsx(
                            'form-select form-select-solid  mb-3 mb-lg-0',
                            {'is-invalid': touchedFields?.currency && errors?.currency},
                            {
                              'is-valid': touchedFields?.currency && !errors?.currency,
                            }
                          )}
                        >
                          <option value=''>
                            {intl.formatMessage({id: 'FORM.PLACEHOLDER.SELECT'})}
                          </option>
                          {initialdata &&
                            initialdata?.currency &&
                            initialdata?.currency.map((opt: any, index: number) => (
                              <option key={index} value={String(opt.id)}>
                                {opt.name}
                              </option>
                            ))}
                        </select>

                        {touchedFields?.currency && errors?.currency && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{errors?.currency?.message}</span>
                            </div>
                          </div>
                        )}

                        {/* end::Input */}
                      </div>
                    </div>
                    <div className='col-3'>
                      {' '}
                      <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label
                          className='required fw-bold fs-6 mb-2 w-100'
                          style={{textAlign: 'left'}}
                        >
                          {intl.formatMessage({id: 'FORM.LABEL.MAX_STOPPING_DAYS'})}
                        </label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                          type='number'
                          {...register('max_stopping_days')}
                          placeholder={''}
                          className={clsx(
                            'form-control form-control-solid  mb-3 mb-lg-0',
                            {
                              'is-invalid':
                                touchedFields?.max_stopping_days && errors?.max_stopping_days,
                            },
                            {
                              'is-valid':
                                touchedFields?.max_stopping_days && !errors?.max_stopping_days,
                            }
                          )}
                        />
                        {touchedFields?.max_stopping_days && errors?.max_stopping_days && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{errors?.max_stopping_days?.message}</span>
                            </div>
                          </div>
                        )}

                        {/* end::Input */}
                      </div>
                    </div>
                    <div className='col-3'>
                      {' '}
                      <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label
                          className='required fw-bold fs-6 mb-2 w-100'
                          style={{textAlign: 'left'}}
                        >
                          {intl.formatMessage({id: 'FORM.LABEL.APPOINTMENT_CANCELLATION_LIMIT'})}
                        </label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                          type='number'
                          {...register('appointment_cancellation_limit')}
                          placeholder={''}
                          className={clsx(
                            'form-control form-control-solid  mb-3 mb-lg-0',
                            {
                              'is-invalid':
                                touchedFields?.appointment_cancellation_limit &&
                                errors?.appointment_cancellation_limit,
                            },
                            {
                              'is-valid':
                                touchedFields?.appointment_cancellation_limit &&
                                !errors?.appointment_cancellation_limit,
                            }
                          )}
                        />
                        {touchedFields?.appointment_cancellation_limit &&
                          errors?.appointment_cancellation_limit && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>
                                  {errors?.appointment_cancellation_limit?.message}
                                </span>
                              </div>
                            </div>
                          )}

                        {/* end::Input */}
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-2'>
                      <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='form-check form-check-custom  align-items-start'>
                          <input
                            className='form-check-input me-1'
                            type='checkbox'
                            {...register('is_relational')}
                            defaultChecked={watchFormField?.is_relational}
                            onChange={(event) => {
                              setValue('is_relational', !watchFormField?.is_relational)
                              if (event.target.checked == false) {
                                setValue('relational_lectures', [])
                              } else {
                                setValue('auto_appointment', false)
                                setValue('auto_credit_decrease', true)
                              }
                            }}
                            disabled={type === 'update'}
                          />

                          <span className='form-check-label d-flex flex-column align-items-start me-6'>
                            <span className='fw-bolder fs-5 mb-0'>
                              {intl.formatMessage({id: 'FORM.LABEL.IS_RELATIONAL'})}
                              <span className='text-warning'>**</span>
                            </span>
                          </span>
                        </label>

                        {/* end::Input */}
                      </div>
                      <div className='fv-plugins-message-container w-100'>
                        <div className='fv-help-block'></div>
                      </div>
                    </div>

                    <div className='col-10'>
                      {watchFormField?.is_relational ? (
                        <div className='fv-row mb-7'>
                          {/* begin::Label */}
                          <label
                            className='required fw-bold fs-6 mb-2 w-100'
                            style={{textAlign: 'left'}}
                          >
                            {intl.formatMessage({id: 'FORM.LABEL.RELATIONAL_LECTURES'})}
                          </label>
                          {/* end::Label */}

                          <Controller
                            control={control}
                            name='relational_lectures'
                            render={({field: {onChange, onBlur, value, ref, name}}) => (
                              <Typeahead
                                id='modal_create_and_update_package_relational_lectures'
                                clearButton
                                dropup
                                multiple
                                labelKey={'name'}
                                isValid={
                                  touchedFields[name] != undefined &&
                                  touchedFields[name] &&
                                  (errors[name] == undefined || !errors[name])
                                }
                                isInvalid={
                                  touchedFields[name] != undefined && errors[name] != undefined
                                }
                                instanceRef={ref}
                                options={
                                  lectureData?.data?.data == undefined
                                    ? []
                                    : lectureData?.data?.data
                                }
                                onChange={onChange}
                                onBlur={onBlur}
                                selected={value as Option[]}
                                emptyLabel={intl.formatMessage({id: 'FORM.WARNING.EMPTY_LABEL'})}
                              />
                            )}
                          />

                          {touchedFields?.relational_lectures && errors?.relational_lectures && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{errors?.relational_lectures?.message}</span>
                              </div>
                            </div>
                          )}

                          {/* end::Input */}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-3'>
                      <div className='fv-row mb-7'>
                        <label className='form-check form-check-custom  align-items-start'>
                          <input
                            className='form-check-input me-1'
                            type='checkbox'
                            {...register('status')}
                            defaultChecked={watchFormField?.status}
                            onChange={() => {
                              setValue('status', !watchFormField?.status)
                            }}
                          />

                          <span className='form-check-label d-flex flex-column align-items-start me-6'>
                            <span className='fw-bolder fs-5 mb-0'>
                              {intl.formatMessage({id: 'FORM.LABEL.STATUS'})}
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className='col-3'>
                      <div className='fv-row mb-7'>
                        <label className='form-check form-check-custom  align-items-start'>
                          <input
                            className='form-check-input me-1'
                            type='checkbox'
                            {...register('auto_appointment')}
                            defaultChecked={watchFormField?.auto_appointment}
                            onChange={() => {
                              setValue('auto_appointment', !watchFormField?.auto_appointment)
                            }}
                            disabled={watchFormField?.is_relational === true ? true : false}
                          />

                          <span className='form-check-label d-flex flex-column align-items-start me-6'>
                            <span className='fw-bolder fs-5 mb-0'>
                              {intl.formatMessage({id: 'FORM.LABEL.AUTO_APPOINTMENT'})}
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className='col-3'>
                      <div className='fv-row mb-7'>
                        <label className='form-check form-check-custom  align-items-start'>
                          <input
                            className='form-check-input me-1'
                            type='checkbox'
                            {...register('auto_credit_decrease')}
                            defaultChecked={watchFormField?.auto_credit_decrease}
                            onChange={() => {
                              setValue(
                                'auto_credit_decrease',
                                !watchFormField?.auto_credit_decrease
                              )
                            }}
                            disabled={watchFormField?.is_relational === true ? true : false}
                          />

                          <span className='form-check-label d-flex flex-column align-items-start me-6'>
                            <span className='fw-bolder fs-5 mb-0'>
                              {intl.formatMessage({
                                id: 'FORM.LABEL.CREDIT_DEDUCTION_IF_NO_APPOINTMENT_IS_MADE',
                              })}
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>

                    <div className='col-3'>
                      <div className='fv-row mb-7'>
                        <label className='form-check form-check-custom  align-items-start'>
                          <input
                            className='form-check-input me-1'
                            type='checkbox'
                            {...register('opportunity_situation')}
                            defaultChecked={watchFormField?.opportunity_situation}
                            onChange={() => {
                              setValue(
                                'opportunity_situation',
                                !watchFormField?.opportunity_situation
                              )
                            }}
                          />

                          <span className='form-check-label d-flex flex-column align-items-start me-6'>
                            <span className='fw-bolder fs-5 mb-0'>
                              {intl.formatMessage({id: 'FORM.LABEL.OPPORTUNITY_SITUATION'})}
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className='col-3'>
                      <div className='fv-row mb-7'>
                        <label className='form-check form-check-custom  align-items-start'>
                          <input
                            className='form-check-input me-1'
                            type='checkbox'
                            {...register('tax_inclusive')}
                            defaultChecked={watchFormField?.tax_inclusive}
                            onChange={() => {
                              setValue('tax_inclusive', !watchFormField?.tax_inclusive)
                            }}
                          />

                          <span className='form-check-label d-flex flex-column align-items-start me-6'>
                            <span className='fw-bolder fs-5 mb-0'>
                              {intl.formatMessage({id: 'FORM.LABEL.TAX_INCLUSIVE'})}
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>

                    {/* <div className='col-3'>
                      <div className='fv-row mb-7'>
                        <label className='form-check form-check-custom  align-items-start'>
                          <input
                            className='form-check-input me-1'
                            type='checkbox'
                            {...register('marketplace_status')}
                            defaultChecked={watchFormField?.marketplace_status}
                            onChange={() => {
                              setValue('marketplace_status', !watchFormField?.marketplace_status)
                            }}
                          />

                          <span className='form-check-label d-flex flex-column align-items-start me-6'>
                            <span className='fw-bolder fs-5 mb-0'>
                              pilatesbul'da satılsın
                            </span>
                          </span>
                        </label>
                      </div>
                    </div> */}
                  </div>

                  <div className='separator separator-dashed mt-2' />
                  <div className='alert alert-warning d-flex align-items-center p-1'>
                    <div className='d-flex flex-column'>
                      <span>{`** ${intl.formatMessage({
                        id: 'FORM.LABEL.IS_RELATIONAL_WARNING',
                      })}`}</span>
                    </div>
                  </div>
                </div>
                {/* end::Scroll */}

                {/* begin::Actions */}

                <div className='text-center pt-15'>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-users-modal-action='submit'
                    disabled={isLoading}
                  >
                    <span className='indicator-label'>
                      {type === 'create'
                        ? intl.formatMessage({id: 'FORM.BUTTON.SAVE'})
                        : intl.formatMessage({id: 'FORM.BUTTON.UPDATE'})}
                    </span>
                    {isLoading && (
                      <span className='indicator-progress'>
                        {intl.formatMessage({id: 'MESSAGE.PLEASE_WAITING'})}{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
                {/* end::Actions */}
              </form>
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className={clsx('modal-backdrop fade', {show: fade})} />
      {/* end::Modal Backdrop */}
    </>
  )
}

export {FormModal}
