//React
import React, {useEffect, useRef} from 'react'

//i18n - react-intl - internationalization - locale -localization
import {useIntl} from 'react-intl'

//Helpers
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'

//React Router Dom
import {useNavigate} from 'react-router-dom'

//Data Fsn
import {addDays, subDays, parseISO, startOfMonth, endOfMonth} from 'date-fns'

//getLocaleConfig
import {dateFormat, startOfWeekAndEndOfWeek} from '../../../../app/i18n/Metronici18n'

type Props = {
  className: string
  title: string
  bgPath: string
  today: string | number
  week: string | number
  month: string | number
  total: string | number
  type?: string
}

const PackageWidget: React.FC<Props> = ({
  className,
  title,
  bgPath,
  today,
  week,
  month,
  total,
  type,
}) => {
  const intl = useIntl()
  const navigate = useNavigate()

  const {startOfThisWeek, endOfThisWeek} = startOfWeekAndEndOfWeek(new Date())
  console.log(startOfThisWeek)
  console.log(endOfThisWeek)
console.log(dateFormat(endOfThisWeek, 'yyyy-MM-dd'))
  const now = new Date()
  const startOfThisMonth = startOfMonth(now)
  const endOfThisMonth = endOfMonth(now)

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}

      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body p-0'>
        {/* begin::Chart */}
        <div
          className={`d-flex flex-column justify-content-center align-items-center card-rounded h-250px `}
          style={{
            backgroundImage: bgPath,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <span className='display-5 d-block text-center text-white'>{title}</span>
          <div className='h-3px w-80px bg-white mt-10' />
        </div>
        {/* end::Chart */}
        {/* begin::Stats */}
        <div className='card-p mt-n20 position-relative'>
          {/* begin::Row */}
          <div className='row g-0'>
            {/* begin::Col */}
            <div
              className='col px-6 py-8 rounded-2 me-7 mb-7'
              style={{backgroundColor: '#F9F9F9', cursor: 'pointer'}}
              onClick={() => {
                if (type === 'sale') {
                  navigate(
                    `/reports/memberships-starts?startDate=${dateFormat(
                      new Date(),
                      'yyyy-MM-dd'
                    )}&endDate=${dateFormat(new Date(), 'yyyy-MM-dd')}`
                  )
                }
                if (type === 'finish') {
                  navigate(
                    `/reports/memberships-ends?startDate=${dateFormat(
                      new Date(),
                      'yyyy-MM-dd'
                    )}&endDate=${dateFormat(new Date(), 'yyyy-MM-dd')}`
                  )
                }
              }}
            >
              <span className='display-5 fw-semibold d-block text-center text-gray-700'>
                {today}
              </span>
              <span className='d-block fw-semibold fs-1 text-center text-muted'>
                {intl.formatMessage({id: 'DASHBOARD.TODAY'})}
              </span>
            </div>
            {/* end::Col */}
            {/* begin::Col */}
            <div
              className='col px-6 py-8 rounded-2 mb-7'
              style={{backgroundColor: '#F9F9F9', cursor: 'pointer'}}
              onClick={() => {
                if (type === 'sale') {
                  navigate(
                    `/reports/memberships-starts?startDate=${dateFormat(
                      startOfThisWeek,
                      'yyyy-MM-dd'
                    )}&endDate=${dateFormat(endOfThisWeek, 'yyyy-MM-dd')}`
                  )
                }
                if (type === 'finish') {
                  navigate(
                    `/reports/memberships-ends?startDate=${dateFormat(
                      startOfThisWeek,
                      'yyyy-MM-dd'
                    )}&endDate=${dateFormat(endOfThisWeek, 'yyyy-MM-dd')}`
                  )
                }
              }}
            >
              <span className='display-5 fw-semibold d-block text-center text-gray-700'>
                {week}
              </span>
              <span className='d-block fw-semibold fs-1 text-center text-muted'>
                {intl.formatMessage({id: 'DASHBOARD.WEEK'})}
              </span>
            </div>
            {/* end::Col */}
          </div>
          {/* end::Row */}
          {/* begin::Row */}
          <div className='row g-0'>
            {/* begin::Col */}
            <div
              className='col px-6 py-8 rounded-2 me-7  mb-7'
              style={{backgroundColor: '#F9F9F9', cursor: 'pointer'}}
              onClick={() => {
                if (type === 'sale') {
                  navigate(
                    `/reports/memberships-starts?startDate=${dateFormat(
                      startOfThisMonth,
                      'yyyy-MM-dd'
                    )}&endDate=${dateFormat(endOfThisMonth, 'yyyy-MM-dd')}`
                  )
                }
                if (type === 'finish') {
                  navigate(
                    `/reports/memberships-ends?startDate=${dateFormat(
                      startOfThisMonth,
                      'yyyy-MM-dd'
                    )}&endDate=${dateFormat(endOfThisMonth, 'yyyy-MM-dd')}`
                  )
                }
              }}
            >
              <span className='display-5 fw-semibold d-block text-center text-gray-700'>
                {month}
              </span>
              <span className='d-block fw-semibold fs-1 text-center text-muted'>
                {intl.formatMessage({id: 'DASHBOARD.MONTH'})}
              </span>
            </div>
            {/* end::Col */}
            {/* begin::Col */}
            <div
              className='col px-6 py-8 rounded-2  mb-7'
              style={{backgroundColor: '#F9F9F9', cursor: 'pointer'}}
              onClick={() => {
                if (type === 'sale') {
                  navigate(
                    `/reports/memberships-detail?startDate=${dateFormat(
                      new Date(),
                      'yyyy-MM-dd'
                    )}&endDate=${dateFormat(addDays(new Date(), 30), 'yyyy-MM-dd')}`
                  )
                }
                if (type === 'finish') {
                  navigate(`/reports/memberships-detail?startDate=&endDate=`)
                }
              }}
            >
              <span className='display-5 fw-semibold d-block text-center text-gray-700'>
                {total}
              </span>
              <span className='d-block fw-semibold fs-1 text-center text-muted'>
                {intl.formatMessage({id: 'DASHBOARD.TOTAL'})}
              </span>
            </div>
            {/* end::Col */}
          </div>
          {/* end::Row */}
        </div>
        {/* end::Stats */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {PackageWidget}
